import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "./../components/layout/Main"
import BlogRoll from "./../components/BlogRoll"
import { Heading } from "../components/styled/BlogRollHeading"
import { BackLink } from "../components/styled/StyledLink"

class TagRoute extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const tag = this.props.pageContext.tag
    const tagHeader = `Izabrana oznaka “${tag}”`

    return (
      <Layout pageContext={this.props.pageContext}>
        <div>
          <Heading>{tagHeader}</Heading>
          <BackLink to="/">x</BackLink>
          <BlogRoll posts={posts} />
        </div>
      </Layout>
    )
  }
}

export default TagRoute

export const pageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            category
            description
            date(formatString: "DD-MMM-YYYY")
            thumbnail_image {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
